const langs=[
    {
        btn_text: 'RU',
        val:'ru'
    },
    {
        btn_text: 'EN',
        val:'en'
    },
    {
        btn_text: 'DE',
        val:'de'
    },
    {
        btn_text: 'FR',
        val:'fr'
    },
    {
        btn_text: 'IT',
        val:'it'
    },
    {
        btn_text: 'AR',
        val:'ar'
    },
    {
        btn_text: 'ZH',
        val:'zh'
    },
]
export default langs