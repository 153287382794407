import React, { Component, useState } from "react";
import { css } from "emotion";
import styles from "../../constants/styles";
import fonts from "../../constants/fonts";
import { fileLoadUrl } from "../../constants/urls"
import PDFReader from '../PDFReader'
import Modal from 'react-modal';

const button_style = css({
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#fff',
    background: 'linear-gradient(278.37deg,#5069F8 16.83%,#01DCEA 82.51%)',
    textAlign: 'center',
    zIndex: '2',
    position: 'relative',
    padding: '5px 50px',
    justifyContent: 'center',
    borderRadius: '30px',
    height: '60px',
    cursor: 'pointer'
});
const link_style = css({
    border: 'none',
    background: 'none',
    padding: '0px',
    height: 'auto',
    cursor: 'pointer',
    color: 'inherit',
    textDecoration: 'underline'
})
const modal_style = css({
    '&__modal-wrap': {
        width: '90%',
        margin: '0 auto',
        backgroundColor: 'rgba(255,255,255, 0.5)',
        borderRadius: '15px',
    },
    [styles.bpm(700)]: {
        '&__modal-wrap': {
            marginTop: '70px',
            width: '90%',
            padding: '15px',
        },
    },
    [styles.bp(700)]: {
        '&__modal-wrap': {
            marginTop: '5px',
            width: '98%',
            paddingLeft: '25px',
            paddingRight: '25px',
            'button': {
                marginTop: '10px'
            }
        },
    }
});


const css_style = css({
    display: 'block',
    position: 'relative',
    width: '100%',

    '&._line': {
        '&::after': {
            content: '""',
            display: 'block',
            ...styles.absolute('calc(100% + 30px)', '0', 'auto', '0'),
            width: '2px',
            height: '50px',
            background: styles.colors.aqua
        }
    },

    'h1, h2, h3, h4, h5': {
        marginTop: '60px',

        '&:first-of-type': {
            marginTop: '0'
        }
    },

    'h1': {
        ...fonts.p0,
        marginBottom: '35px'
    },
    'h2': {
        ...fonts.p1,
        marginBottom: '30px'
    },
    'h3': {
        ...fonts.p2,
        marginBottom: '25px'
    },
    'h4': {
        ...fonts.p3,
        marginBottom: '20px'
    },
    'h5': {
        ...fonts.p4,
        marginBottom: '15px'
    },

    'p': {
        ...fonts.p5,
        marginBottom: '20px',

        '&:last-child': {
            marginBottom: '0'
        }
    },

    'a': {
        color: styles.colors.cyan,

        '&:hover': {
            textDecoration: 'underline'
        }
    },

    'strong': {
        fontWeight: '700'
    },

    'ul, ol': {
        ...fonts.list
    },

    'ul': {
        ...fonts.ul
    },

    'ol': {
        ...fonts.ol
    },

    [styles.bpm(700)]: {
        '&._ta-center': {
            textAlign: 'center'
        },
    },

    [styles.bp(1300)]: {
        '&._line': {
            '&::after': {
                height: '40px',
            }
        },
    },

    [styles.bp(700)]: {
        '&._line': {
            '&::after': {
                top: 'calc(100% + 20px)',
                width: '1px',
                height: '20px',
            }
        },

        'h1, h2, h3, h4, h5': {
            marginTop: '40px',
        },

        'h1': {
            marginBottom: '30px'
        },
        'h2': {
            marginBottom: '25px'
        },
        'h3': {
            marginBottom: '20px'
        },
        'h4': {
            marginBottom: '15px'
        },
        'h5': {
            marginBottom: '10px'
        },

        'p': {
            marginBottom: '10px',
        },
    },
});

const FileBlock = (props) => {
    const [file, setFile] = useState(false)
    const viewPdfFile = (url) => {
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setFile(data.file)
            })
            .catch((err) => console.error('PDF', err));
    }
    function afterOpenModal() { }

    function closeModal() {
        setFile(false);
    }
    const { title, caption, url, mod = '', isLink = false } = props.data;
    const fileUrl = `${fileLoadUrl}/download_file.php?fileUrl=${url}`
    const modal = <Modal
        isOpen={file}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
            overlay: {
                zIndex: '99999',
                backgroundColor: 'rgba(255,255,255, 0.1)'
            },
        }}
        className={`${modal_style}__modal-wrap`}
        contentLabel={title}
    >
        <button style={
            { background: 'none', 
            fontSize: '27px', 
            cursor: 'pointer', 
            color: 'indigo',
            transform: 'scaleY(0.75)',
        }} onClick={closeModal}>X</button>
        <PDFReader file={file} />
    </Modal>
    const modalContent = isLink ? <><span className={link_style} onClick={() => viewPdfFile(fileUrl)}>{caption}</span> {modal}</> :
        <div className={`${css_style} ${mod}`}>
            <h4>{title ? require("html-react-parser")(title) : null}</h4>
            <button className={button_style} onClick={() => viewPdfFile(fileUrl)}>{caption}</button>
            {modal}
        </div>
    return (
        <>{ modalContent }</>
    )
}
export default FileBlock;
