import * as React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PDFReader = (props) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
});
  // https://unpkg.com/pdfjs-dist@3.6.172/legacy/build/pdf.worker.js
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/legacy/build/pdf.worker.min.js">
      <div
        style={{
          height: "750px",
          maxWidth: "900px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Viewer
          fileUrl={props.file}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>
  );
};

export default PDFReader;