import React from "react";
import { Marker, MapContainer, TileLayer } from "react-leaflet";
import drawLocales from 'leaflet-draw-locales'
import Leaflet from 'leaflet'

import "leaflet/dist/leaflet.css";
import iconUrl from "../../images/svg/pin.svg";

const MAP_BASE_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

const ATTRIBUTION = "&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors";

const MapOSMContainer = (props) => {
  let mapNode = React.createRef(); //useRef(0);
  const icon = new Leaflet.Icon({
    iconUrl,
  });

  const onMapLoaded = (m) => {
    console.log('[whenReady]', m)
  };
  const onMapCreated = (m) => {
    console.log('[whenCreated]', m)
    setTimeout(()=> {
      console.log('mapNode=', mapNode)
    }, 2000)
  }

 drawLocales('fr')
 const { coords } = props.data;
  return (
    <MapContainer
      style={{
        width: "98vw",
        height: "98vh",
        zIndex: "0"
      }}
      ref={mapNode}
      center={[coords[0], coords[1]]}
      zoom={14}
      whenReady={onMapLoaded}
      whenCreated={onMapCreated}
    >
      <TileLayer url={MAP_BASE_URL} attribution={ATTRIBUTION} />
      <Marker position={[coords[0], coords[1]]} icon={icon}></Marker>
    </MapContainer>
  );
};
export default MapOSMContainer;