import React from "react";
import urls from "../../constants/urls";
import ProjectBgSlider from "../../components/ProjectBgSlider";
import ProjectDescription from "../../components/ProjectDescription";
import HelpBlock from "../../components/HelpBlock";
import PageHeader from "../../components/PageHeader";
import TourBubble from "../../components/TourBubble";
import ValuesSlider from "../../components/ValuesSlider";
import CompareImages from "../../components/CompareImages";
import fetchQuery from "../../utils/fetch";
import ContentBlock from '../../components/ContentBlock';
import { css } from "emotion";
import styles from "../../constants/styles";
import * as rdd from 'react-device-detect';

const paging_css = css({
    '&__nav': {
        position: 'fixed',
        height: '0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // bottom: '20%',
        // padding: '20px',
        zIndex: '999'
    },
    '&__prev': {
        '&::before': {
            content: "'⯇'",
        },
        transform: 'scale(.8, 1) perspective(300px) rotateY(45deg)'
    },
    '&__next': {
        '&::after': {
            content: "'⯈'",
        },
        transform: 'scale(.8, 1) perspective(300px) rotateY(-45deg)'
    },
    '&__btn': {
        '&::after,&::before': {
            fontSize: '120px',
            color: '#fff',
            transition: 'all 0.3s ease',
            alignItems: 'center',
            cursor: 'pointer',
            opacity: '0.1'
        },
        '&:hover': {
            opacity: '1',
        },
        cursor: 'pointer',
        background: 'none',
        // transform: 'scale(.5, 1)',
        opacity: '0.8',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '150px',
        // backgroundColor: 'red',
        borderRadius: '20px',
    },
    '&__effect-jump': {
        // bottom: '45%!important',
        transition: 'bottom 1s'
    },
    [styles.bp(700)]: {
        '&__nav': {
            bottom: '45%',
            padding: '0px',
        },
        '&__btn': {
            '&::after,&::before': {
                fontSize: '60px'
            }
        },
        '&__effect-jump': {
            bottom: '57%!important',
        },
    },
    [styles.bpm(700)]: {
        '&__nav': {
            bottom: '20%',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
        '&__effect-jump': {
            bottom: '45%!important',
        },
    }
})
class ProjectPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],
            header: {},
            bg_slider: {},
            description: {},
            tour_bubble: {},
            values_slider: {},
            compare: {},
            help_block: {},
            isJump: false,
        };

        this.navigator = {
            prev: {},
            next: {},
            active: {},
        }
    }

    scrollDetect(self) {
        var lastScroll = 0;
        let isJump = false
        let percent = rdd.isMobile ? 50 : 30

        window.onscroll = function () {
            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
            let docHeight = document.body.scrollHeight
            if (currentScroll > 0 && lastScroll <= currentScroll) {
                lastScroll = ( docHeight - currentScroll ) / docHeight * 100;
                if(lastScroll <= percent) isJump = true
                else isJump = false

                // console.log('currentScroll=', currentScroll, 'docHeight=', docHeight, 'percent=', percent)
            }
            // else {
            //     lastScroll = ( docHeight - currentScroll ) / docHeight * 100;
            //     console.log(lastScroll)
            // }
            self.setState({isJump})
        }
    }

    componentDidMount() {
        const { match } = this.props;
        this.scrollDetect(this);
        return this.loadProject(match.params.id)
    }
    loadProject(id) {
        return fetchQuery.bind(this)({
            url: urls.project,
            method: 'POST',
            body: JSON.stringify({
                "id": Number(id)
            })
        });
    }
    initNavigation(list) {
        let { listIds = [], lastId, index } = this.getPage(list)

        // Prev
        const indexPrev = index - 1
        const prevActive = list[indexPrev] //listIds[index]
        if (indexPrev >= 0 && prevActive !== undefined) {
            this.navigator.prev = prevActive
        }

        // Next
        const indexNext = index + 1
        const nextActive = list[indexNext] //listIds[index]
        if (indexNext < listIds.length && nextActive !== undefined) {
            this.navigator.next = nextActive
        }

        // Current
        this.navigator.active = list.find(p => p.id === lastId)
    }

    getPage(list) {
        const { match, location } = this.props;
        // const prevId = match.params.id
        const listIds = list.map(p => p.id)
        const lastId = Number(location.state?.activeId ? location.state.activeId : match.params.id)
        let index = listIds.indexOf(lastId)
        return { listIds, lastId, index }
    }
    prevProject(list) {
        try {
            let { listIds = [], index } = this.getPage(list)
            index = index - 1
            const activeId = listIds[index]
            // console.log({index, lastId, activeId, listIds})
            if (index >= 0 && activeId !== undefined) this.props.history.push(`/projects/${activeId}`, { activeId })
        } catch (e) {
            console.error('NAV', e)
        }
    }
    nextProject(list) {
        try {
            let { listIds = [], index } = this.getPage(list)
            index = index + 1
            const activeId = listIds[index]
            // console.log({index, lastId, activeId, listIds})
            if (index < listIds.length && activeId !== undefined) this.props.history.push(`/projects/${activeId}`, { activeId })
        } catch (e) {
            console.error('NAV', e)
        }
    }
    render() {
        const { header, bg_slider, description, tour_bubble, help_block, compare, values_slider, content_block = null, list = [] } = this.state;
        this.initNavigation(list)
        const imgPrev = this.navigator.prev.img ? { backgroundImage: `url(${this.navigator.prev.img})` } : {}
        const imgNext = this.navigator.next.img ? { backgroundImage: `url(${this.navigator.next.img})` } : {}

        return (
            <React.Fragment>
                <PageHeader mod='_small _clear-b' data={header} />
                <ProjectBgSlider data={bg_slider} />
                <ProjectDescription data={description} />
                {tour_bubble?.popup_data?.content?.tour.link && <TourBubble data={tour_bubble} />}
                <ValuesSlider data={values_slider} />
                <CompareImages data={compare} />
                {content_block && content_block.component.data.popup_data.content.video.id && <ContentBlock key={0} data={content_block} />}
                <HelpBlock data={help_block} />
                <div className={`${paging_css}__nav ${this.state.isJump ? `${paging_css}__effect-jump` : ``}`}>
                    <button className={`${paging_css}__prev ${paging_css}__btn`} onClick={() => this.prevProject(list)} title={this.navigator.prev.title} style={imgPrev}>
                    </button>
                    <button className={`${paging_css}__next ${paging_css}__btn`} onClick={() => this.nextProject(list)} title={this.navigator.next.title} style={imgNext}>
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

export default ProjectPage;


