import Cookies from 'universal-cookie';
import { getGeoInfo } from './geo';
// import langs from '../constants/langs'

export default async function fetchQuery(obj) {
	const cookies = new Cookies()
	let savedLang = cookies.get('site-lang');
	if(!savedLang) {
		const { country_code } = await getGeoInfo()
		const code = country_code?.toLowerCase()
		// const lang = langs.find(lang => lang.val == code)
		// savedLang = lang ? lang.val : 'en'
		savedLang = code === 'ru' ? 'ru' : 'en'
	}
	const
		{ url, method, body, customSuccess } = obj,
		getParameter = savedLang ? `?lang=${savedLang}` : '',
		fetchUrl = `${url}${getParameter}`,
		fetchObj = {
			method: method || 'GET',
			headers: {
				"Content-Type": "application/json",
			},
		};

	if (body) fetchObj.body = body;
	return fetch(fetchUrl, fetchObj)
		.then((response) => response.json())
		.then((responseJson) => {
			const r = {...responseJson, ...{ lang: { "cookie_name": "site-lang", "btn_text": savedLang.toUpperCase(), "val": savedLang } }}
			if (customSuccess) {
				customSuccess(r);
			} else {
				this.setState({
					...r
				});
			}
		})
		.catch((error) => {
			console.error(error);
		});
}

