import React from "react";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import iconUrl from "../../images/svg/pin.svg"
const MapContainer = (props) => {
  const { coords } = props.data;

  return <YMaps query={{ load: "package.full" }}>
    <Map
      state={{
        center: [coords[0], coords[1]],
        zoom: 14,
        controls: []
      }}
      width="100vw"
      height="100vh"
    >
      <Placemark
        geometry={[coords[0], coords[1]]}
        options={{
          iconLayout: 'default#image',
          iconImageHref: iconUrl,
          // iconImageSize: [32, 32],
        }} />
    </Map>
  </YMaps>
}

export default MapContainer