import { SmartCaptcha } from "@yandex/smart-captcha";
import React, { useState } from "react";
import { css } from "emotion";

const css_style = css({
  display: 'block',
  margin: '0 auto',
  maxWidth: '300px',
});

export default function Captcha({ onSuccess, onError, lang = 'ru' }) {
  const [key, setKey] = useState(0);
  const [token, setToken] = useState('');

  const handleReset = () => setKey((prev) => prev + 1);
console.log('LANG', lang)
  return (
    <div className={`${css_style}`}>
      { /*<button onClick={handleReset}>Reset captcha</button>*/ }
      <SmartCaptcha
        key={key}
        sitekey="U7bnwGLbYiElqryaP1Qq714dW2BmcwsexmmZriSg"
        hl={lang}
        language={lang}
        test={false}
        onTokenExpired={onError}
        onSuccess={onSuccess}
        visible={true}
      />
    </div>
  );
}
